export type CurrentModelsIdsType = {
    seriousModelId_1: string,
    seriousModelId_2: string,
    seriousModelId_3: string,
    seriousModelId_4: string,
    seriousModelId_5: string,
    creativeModelId: string,
    rusModelId_1: string,
    rusModelId_2: string,
    balancedModelId_1: string
    balancedModelId_2: string
    balancedModelId_3: string
}

export const currentModelsIds:CurrentModelsIdsType = {
    seriousModelId_1: "02a80bec-d589-4966-8f61-4ec3c30fbd9a",
    seriousModelId_2: "1aeb52d6-d8fb-4760-89a4-1157d931c531",
    seriousModelId_3: "9ae4971e-4d11-444a-b41e-ea66b52f020e",
    seriousModelId_4: "268e3afb-9164-48d0-a298-6b55981038c6",
    seriousModelId_5: "dad6cfa4-0934-4ee5-9dd2-56c1694746fb",

    creativeModelId: "cb7194e2-edf1-479b-bc10-ef5b4b40562e",

    rusModelId_1: "4218c187-0a0a-442c-bee0-e1c2dca5632b",
    rusModelId_2: "599ab208-b0ef-4a1a-a541-66742d04a4c3",
    
    // balancedModelId_1: "b3377414-0511-499a-893b-6bb47e2d5f56",
    balancedModelId_1: "d5448ffc-664c-49bd-857a-bf2a0a676853",
    balancedModelId_2: "e935cc32-5af5-4c9b-aa71-645260d764b7",
    balancedModelId_3: "9d71440c-32ea-4bfb-bb80-ddf32138dff1",
}