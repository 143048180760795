import { currentModelsIds } from "./models"
import { ModelSelectionBalancedIcon, ModelSelectionCreativelyIcon, ModelSelectionRusIcon, ModelSelectionSeriouslyIcon, ModelSelectionUndefined } from "./model-selection-icons"

type currentModelDescriptionFunctionPropsType = {
    modelId: string,
    isSelected: boolean
}

export type ModelDescriptionType = {
    currentModelName: string
    currentModelImage: React.ReactNode
}

export const currentModelDescriptionFunction = (
        props: currentModelDescriptionFunctionPropsType
    ): ModelDescriptionType | undefined => {
    switch (props.modelId) {
        case currentModelsIds.seriousModelId_1:
        case currentModelsIds.seriousModelId_2:
        case currentModelsIds.seriousModelId_3:
        case currentModelsIds.seriousModelId_4:
        case currentModelsIds.seriousModelId_5:
            return {
                currentModelName: "Gemini",
                currentModelImage: <ModelSelectionSeriouslyIcon isSelected={props.isSelected} />
            };
        case currentModelsIds.creativeModelId:
            return {
                currentModelName: "OpenAI",
                currentModelImage: <ModelSelectionCreativelyIcon isSelected={props.isSelected} />
            };
        case currentModelsIds.rusModelId_1:
        case currentModelsIds.rusModelId_2:
            return {
                currentModelName: "YaGPT",
                currentModelImage: <ModelSelectionRusIcon isSelected={props.isSelected} />
            };
        case currentModelsIds.balancedModelId_1:
        case currentModelsIds.balancedModelId_2:
        case currentModelsIds.balancedModelId_3:
            return {
                currentModelName: "Claude",
                currentModelImage: <ModelSelectionBalancedIcon isSelected={props.isSelected} />
            };
        default:
            return undefined;
    }
}