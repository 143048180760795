import { useSelector } from "react-redux";
import { TemplateType } from "../../../../../store/features/questionTypes";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "../../../../../components/modal/modal";
import { Typography } from "../../../../../components/typography/Typography";
import { TypographyVariant } from "../../../../../store/storeConst/enums";
import { Scrollbar } from "../../../../../components/scrollbar";
import { TemplateSection } from "./templateSection/templateSection";

import s from "./myTemplates.module.css";

type MyTemplatesPropsType = {
  onCloseFunction: () => void;
  show: boolean;
};

const MyTemplates = ({ onCloseFunction, show }: MyTemplatesPropsType) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const params = useParams();
  const currentCategoryId: string = params.categoryId || "";
  const templates: Array<TemplateType> = [
    ...useSelector((state: RootState) => state.question.templates),
  ].filter((t) => t.categoryId === currentCategoryId);
  const isTemplatesLoading: boolean = useSelector(
    (state: RootState) => state.question.loadingVars.isTemplatesLoading
  );

  const showTemplateQuestionsList: Array<string> = useSelector(
    (state: RootState) => state.question.templateVars.showTemplateQuestionsList
  );

 

  return (
    <Modal
      className={s.Modal}
      theRef={ref}
      show={show}
      onChange={onCloseFunction}
    >
      <Typography variant={TypographyVariant.H2}>Мои шаблоны:</Typography>
      {templates.length === 0 ? (
        <div>
          <label>Пока у Вас нет сохранненых шаблонов в данном разделе.</label>
        </div>
      ) : (
        <Scrollbar className={s.Scroll}>
          <div className={s.Sections}>
            {templates.map((template: TemplateType) => (
              <TemplateSection
                key={template.id}
                template={template}
                onClose={onCloseFunction}
              />
            ))}
          </div>
        </Scrollbar>
      )}
    </Modal>
  );
};

export default MyTemplates;
