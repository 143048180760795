import { toast } from "react-toastify";
import { Button } from "../../../../components/button/button"
import { RootState, useAppDispatch, useAppSelector } from "../../../../store/store";
import { ChangingOneQuestionType } from "../../../../store/features/questionTypes";
import { addWhichCategoryGptLoadingAC } from "../../../../store/features/answer/answerSlice";
import { useSelector } from "react-redux";
import { sendVisionGPTThunk } from "../../../../store/features/answer/answer-gpt-vision-thunk";
import { sendDocPdfGptThunk } from "../../../../store/features/answer/answer-gpt-doc-thunks";
import { chatGptSendThunk } from "../../../../store/features/answer/answer-gpt-chat-thunks";

import send from "../../../../assets/icons/send.svg";
import { setIsShowQuestionsMobileVersion } from "../../../../store/features/questionSlice";

type PropsType = {
    // type_gpt: CategoryTypeGpt
    imageForVision: File | null
    currentCategoryId: string
    selectModel: string
    mobileSwitch?: () => void;
}

export const SendToGptButton = (props: PropsType) => {

    const dispatch = useAppDispatch();
    const questions = useAppSelector((state) => state.question.questions);
    const whichCategoryGptAnswerLoading: Array<string> = useSelector((state: RootState) => 
        state.answer.loadingVars.whichCategoryGptAnswerLoading);
    const isThisCatAnswerLoading: boolean = whichCategoryGptAnswerLoading.some((el) => props.currentCategoryId === el);
    const gptType = useAppSelector((state) => state.question.QuestionSliceVars.currentGptType)

    const onSendToGPRClickHandler = () => {

        // Переключаем на ответ в моб.версии
        dispatch(setIsShowQuestionsMobileVersion(false))

        const condition: boolean = questions.some(
            (q) => q.isRequired && (q.answer === "" || q.answer === null)
        );
        if (condition) {
            toast.error("Не все обязательные поля заполнены");
        // } else if (props.type_gpt === "gpt_vision" && !props.imageForVision) {
        } else if (gptType === "gpt_vision" && !props.imageForVision) {
            toast.error("Вставьте изображение");
        } else if (gptType === "gpt_files" && !props.imageForVision) {
            toast.error("Вставьте файл");
        } else {
            dispatch(addWhichCategoryGptLoadingAC(props.currentCategoryId));

            const userAnswers: Array<ChangingOneQuestionType> = [...questions].map(
            (q) => {
                return { question_id: q.id, answer: q.answer };
            }
            );
            props.mobileSwitch && props.mobileSwitch();

            
            // if (props.type_gpt === "gpt_chat") {
            if (gptType === "gpt_chat" || gptType === null) {
                dispatch(
                    chatGptSendThunk({
                        question_answer: userAnswers,
                        category_id: {
                            categoryId: props.currentCategoryId,
                            model_gpt_id: props.selectModel && props.selectModel?.length > 0 ? props.selectModel : "",
                        },
                    })
                )
            }

            // if (props.type_gpt === "gpt_vision") {
            if (gptType === "gpt_vision") {
                dispatch(
                    sendVisionGPTThunk({
                        image: props.imageForVision!,
                        request_data: {
                        category_id: props.currentCategoryId,
                        answers: userAnswers,
                        },
                    })
                )
            }
            
            // if (props.type_gpt === "gpt_files") {
            if (gptType === "gpt_files") {
                dispatch(
                    sendDocPdfGptThunk({
                        image: props.imageForVision!,
                        request_data: {
                            category_id: props.currentCategoryId,
                            answers: userAnswers,
                        }
                    })
                )
            }
        }
    };

    return (
        // <div>
        <Button
          id={"generateTextButton"}
        //   className={s.button}
          onClick={onSendToGPRClickHandler}
          img={send}
          disabled={isThisCatAnswerLoading}
        >
          Генерировать текст
        </Button>
        // </div>
    )
}

